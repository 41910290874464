<template>
  <v-app>
    <v-data-table
        :headers="headers"
        :items="students"
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
    >
    </v-data-table>
  </v-app>
</template>
<script>
import {mapGetters} from "vuex";
import {GET_LIST_STUDENT_BY_COURSE} from "@/store/user.module";

export default {
  props: {
    course_slug: {
      type: String
    }
  },
  data() {
    return {
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id"
        },
        {text: "Tên", value: "full_name"},
        {text: "Email", value: "email"},
        {text: "Số điện thoại", value: "phone_number"},
        {text: "Ngày bắt đầu", value: "active_date"},
        {text: "Ngày kết thúc", value: "expiration_date"}
      ],
      page: 1,
      itemsPerPage: 10,
    }
  },
  async created() {
    await this.$store.dispatch(GET_LIST_STUDENT_BY_COURSE, {course_slug: this.course_slug});
  },
  computed: {
    ...mapGetters({
      students: "listStudents"
    })
  },
}
</script>