<template>
  <v-app>
    <div class="add-button-container">
      <v-btn
          color="success"
          ref="addButton"
          @click="createLesson"
      >Thêm bài học
      </v-btn>
    </div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Thêm bài học</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <ValidationObserver ref="save_lesson">
              <v-row>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                        v-model="lesson.title"
                        label="Tên bài học"
                    ></v-text-field>
                    <span class="validate-error text-danger text-danger">
                              {{ errors[0] }}
                            </span>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="lesson.reference_id"
                      :items="lessonsOption"
                      label="Bài học cha"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      type="Number"
                      v-model="lesson.time"
                      label="Thời gian học"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                        v-model="lesson.seo_title"
                        label="Từ khóa"
                    ></v-text-field>
                    <span class="validate-error text-danger text-danger">
                              {{ errors[0] }}
                            </span>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-textarea
                        v-model="lesson.seo_description"
                        label="Key mô tả"
                    ></v-textarea>
                    <span class="validate-error text-danger text-danger">
                              {{ errors[0] }}
                            </span>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="50" style="margin-left: 150px; position: absolute;margin-bottom: 50px; margin-top: 20px"
                 color="blue darken-1" text @click="dialog = false">Hủy
          </v-btn>
          <v-btn
              style="margin-left: 230px; position: absolute;margin-bottom: 50px;margin-top: 20px"
              width="50"
              type="submit"
              form="save-lesson-form"
              color="blue darken-1"
              text
              @click="isEditLesson ? editLesson : saveLesson"
          > Lưu
          </v-btn
          >
          <v-btn
              v-if="isEditLesson"
              style="margin-left: 230px; position: absolute;margin-bottom: 50px;margin-top: 20px"
              width="50"
              type="submit"
              form="save-lesson-form"
              color="blue darken-1"
              text
              @click="editTitle"
          > Lưu
          </v-btn
          >
          <v-btn
              v-if="!isEditLesson"
              style="margin-left: 230px; position: absolute;margin-bottom: 50px;margin-top: 20px"
              width="50"
              type="submit"
              form="save-lesson-form"
              color="blue darken-1"
              text
              @click="saveLesson"
          > Lưu
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <draggable @change="submitOrder" v-model="lessons" handle=".handle">
      <curriculumItem class="curriculum-parent item" v-for="(lesson,lessonIndex) in lessons" :key="lessonIndex"
                      :lesson="lesson"
                      :course_slug="course_slug"
                      @handleAction="handleAction"
                      @editLesson="editLesson"
      >
        <draggable @change="submitOrder" handle=".handle" v-model="lesson.children">
          <template v-if="lesson.open">
            <curriculumItem v-for="(child,childIndex) in lesson.children" :key="childIndex"
                            :lesson="child"
                            :course_slug="course_slug"
                            @handleChildAction="handleChildAction"
                            @editLesson="editLesson"
            ></curriculumItem>
          </template>
        </draggable>
      </curriculumItem>
    </draggable>
  </v-app>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import curriculumItem from "@/components/lesson/LessonMenuItem";
import draggable from 'vuedraggable'

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {
  GET_LIST_LESSON,
  SAVE_LESSON,
  DELETE_LESSON,
  UPDATE_LESSON_TITLE,
  UPDATE_LESSON_ORDER
} from "@/store/lessons.module";
import {GET_EXERCISE_DETAIL} from "@/store/exercises.module";
import {GET_COURSE_DETAIL} from "@/store/courses.module";

import {SUCCESS} from '@/store/alerts.module';

export default {
  props: ["course_slug"],
  components: {
    curriculumItem,
    draggable
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{title: "Giáo trình"}]);
    await this.$store.dispatch(GET_COURSE_DETAIL, {
      course_slug: this.course_slug,
    });
    await this.$store.dispatch(GET_LIST_LESSON, {course_slug: this.course_slug});
  },
  data: () => ({
    componentKey: 0,
    addParentBox: {
      title: "",
      isActive: false,
    },
    childTitle: "",
    parentTitle: "",
    addChildBox: {
      title: "",
      isActive: false,
      parentId: 0,
    },
    openLessonIndex: -1,
    disabled: false,
    dialog: false,
    lesson: {
      title: "",
      reference_id: null,
      course_id: null,
    },
    openId: null,
    isEditLesson: false
  }),
  computed: {
    ...mapState({
      course: (state) => state.courses.detailCourse
    }),
    ...mapGetters({
      exercise: "exerciseDetail"
    }),
    lessonsOption() {
      let arr = [];
      arr.push({
        text: "Không",
        value: 0
      })
      this.lessons.forEach(function (element) {
        let item = {
          text: element.title,
          value: element.id
        }
        arr.push(item);
      });
      return arr;
    },
    lessons: {
      get() {
        return this.$store.state.lessons.lessons
      },
      set(value) {
        this.$store.commit('setListLesson', value)
      }
    }
  },
  watch: {
    lessons: {
      immediate: true,
      handler() {
        let openId = this.openId;
        this.lessons.forEach(function (part, index, theArray) {
          if (part.id === openId) {
            theArray[index].open = true;
          }
        })
      }
    },
  },
  methods: {
    submitOrder() {
      for (let i = 0; i < this.lessons.length; i++) {
        this.lessons[i].order = i;
        let children = this.lessons[i].children;
        if (!children) {
          continue;
        }
        for (let j = 0; j < children.length; j++) {
          children[j].order = j;
        }
      }
      this.$store.dispatch(UPDATE_LESSON_ORDER, {
        lessons: this.lessons
      }).then((data) => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, {
            type: "success_vote",
            message: "Cập nhật vị trí thành công"
          }, {
            root: true
          });
        }
      });
    },
    async editTitle() {
      let result = await this.$refs.save_lesson.validate();
      if (result) {
        if (this.lesson.reference_id) {
          if (!this.lesson.time) {
            this.$toasted.error('Bạn chưa nhập thời gian học !!', {
              position: "top-right",
              duration: 3000
            })

            return false
          }
        }
        await this.$store.dispatch(UPDATE_LESSON_TITLE, {
          lesson: this.lesson
        }).then(data => {
          if (data.status) {
            this.$store.dispatch(GET_LIST_LESSON, {
              course_slug: this.course_slug,
              lesson: this.lessons[this.openLessonIndex],
            });
            this.openId = this.lesson.reference_id;
            this.$toasted.success('Chỉnh sửa bài viết thành công!!', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 3000
            })
            this.resetDialog();
          }
        })
      }
    },
    createLesson() {
      this.lesson = {}
      this.isEditLesson = false
      this.dialog = true
    },
    editLesson(lesson) {
      this.lesson = lesson
      this.isEditLesson = true
      this.dialog = true
    },
    async saveLesson() {
      let result = await this.$refs.save_lesson.validate();
      if (result) {
        if (this.lesson.reference_id) {
          if (!this.lesson.time) {
            this.$toasted.error('Bạn chưa nhập thời gian học !!', {
              position: "top-right",
              duration: 3000
            })

            return false
          }
        }
        let lesson = {
          title: this.lesson.title,
          reference_id: this.lesson.reference_id,
          seo_title: this.lesson.seo_title,
          time: this.lesson.time,
          seo_description: this.lesson.seo_description,
          course_id: this.course.id,
        };
        this.$store
            .dispatch(SAVE_LESSON, {
              lesson: lesson,
            }).then((data) => {
          if (data.status) {
            this.$store.dispatch(GET_LIST_LESSON, {
              course_slug: this.course_slug,
              lesson: this.lessons[this.openLessonIndex],
            });
            this.openId = data.data.reference_id;
            this.resetDialog();
          }
        });
      }
    },
    resetDialog() {
      this.lesson.reference_id = null;
      this.lesson.title = "";
      this.lesson.seo_description = "";
      this.lesson.seo_title = "";
      this.dialog = false;
    },
    redirectPage(lesson_type, lesson_slug, lesson_id) {
      if (lesson_type === 0) {
        this.$router.push({
          path: "/lesson/select",
          query: {lesson_slug: lesson_slug, course_slug: this.course.slug},
        });
      }
      if (lesson_type === 3) {
        this.$router.push({
          path: "/lesson/video",
          query: {lesson_slug: lesson_slug, course_slug: this.course.slug},
        });
      }
      if (lesson_type === 2) {
        this.$router.push({
          path: "/lesson/exam",
          query: {lesson_slug: lesson_slug, course_id: this.course.id},
        });
      }
      if (lesson_type === 1) {
        this.switchExercisePage(lesson_slug, lesson_id);
      }
    },
    async switchExercisePage(lesson_slug, lesson_id) {
      await this.$store.dispatch(GET_EXERCISE_DETAIL, {
        lesson_slug: lesson_slug,
        type: this.exercise.type
      });
      if (this.exercise) {
        await this.$router.push({
          path: "/exercise/detail",
          query: {lesson_slug: lesson_slug, course_id: this.course.id, type: this.exercise.type},
        });
        if(this.exercise.type === 7) {
          this.$router.go(0)
        }
      } else {
        await this.$router.push({
          path: "/exercise/select",
          query: {
            lesson_slug: lesson_slug,
            lesson_id: lesson_id,
            course_id: this.course.id,
          },
        });
      }
    },
    handleChildAction(payload) {
      // view lesson detail
      let lesson = payload.item;
      if (payload.action === 1) {
        this.redirectPage(lesson.type, lesson.slug, lesson.id);
      }
      if (payload.action === 5) {
        confirm("Are you sure you want to delete this item?") &&
        this.$store
            .dispatch(DELETE_LESSON, {
              lesson: lesson,
            }).then((data) => {
          if (data.status) {
            let id = lesson.id;
            this.deleteLessonChild(id);
          }
        });
      }
    },
    deleteLessonChild(id) {
      this.lessons.forEach(function (lesson, index, theArray) {
        if (theArray[index].children.length > 0) {
          theArray[index].children = theArray[index].children.filter(function (child) {
            return child.id !== id;
          });
        }
      });
    },
    handleAction(payload) {
      let lesson = payload.item;
      lesson.course_id = this.course.id;
      if (payload.action === 5) {
        // delete item
        confirm("Are you sure you want to delete this item?") &&
        this.$store
            .dispatch(DELETE_LESSON, {
              lesson: lesson,
            })
            .then((data) => {
              if (data.status) {
                let id = lesson.id;
                this.deleteLessonParent(id)
              }
            });
      }
    },
    deleteLessonParent(id) {
      this.lessons = this.lessons.filter(function (lesson) {
        return lesson.id !== id;
      });
    },
    removeItem(item) {
      if (item.reference_id == 0) {
        for (let i = this.lessons.length - 1; i >= 0; i--) {
          if (this.lessons[i].id === item.id) {
            this.lessons[i].splice(i, 1);
            break;
          }
        }
      }
    },
    // fix thatttttttttttttttttttt
    update(lessonPayload) {
      this.$store
          .dispatch(UPDATE_LESSON_TITLE, {
            lesson: lessonPayload,
          })
          .then(() => {
            this.isEdited = false;
          });
    },
  }
}
</script>

<style lang="scss">
.v-list-item__icon {
  display: none !important;
}

.add-lesson {
  height: 40px !important;
}

.add-button-container {
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
  }
}

.curriculum-parent {
  margin-bottom: 20px;
}
</style>
